
.new-main {
    padding: 12px 0;
    display: flex;
    align-items: center;
    min-height: calc(100vh - var(--header-body));
}
.about-banner {
    width: 100%;
    position: relative;
    &__logo {
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        max-width: 160px;
        .v-image {
            box-shadow: var(--shadow);
        }
    }
    &__inner {
        position: relative;
        width: 100vw;
        height: 160px;
        max-width: 100vw;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .about-banner {
        &__logo {
            max-width: 260px;
        }
        &__inner {
            height: 200px;
        }
    }
}
@media (min-width: 1024px) {
    .about-banner {
        &__logo {
            max-width: 382px;
        }
        &__inner {
            height: 350px;
        }
    }
}
@media (min-width: 1200px) {
}
